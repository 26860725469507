import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row className="about-content">
          <Col className="about-header">
            <h1 className="heading">
              About <span className="accent">Me</span>
            </h1>
            <div className="about-description">
              <p>
                I'm a passionate Full Stack Developer based in Argentina, with a strong
                focus on backend development. I enjoy creating efficient and scalable
                solutions to complex problems.
              </p>
              <p>
                My journey in software development started with a curiosity for
                understanding how things work under the hood, which led me to
                specialize in backend technologies while maintaining a solid
                understanding of frontend development.
              </p>
            </div>
          </Col>
        </Row>

        <Row className="skill-section">
          <Col md={12} className="text-center mb-5">
            <h2 className="section-title">Professional Skillset</h2>
          </Col>
          <Techstack />
        </Row>

        <Row className="tool-section">
          <Col md={12} className="text-center mb-5">
            <h2 className="section-title">Tools I Use</h2>
          </Col>
          <Toolstack />
        </Row>
      </Container>
    </Container>
  );
}

export default About;
