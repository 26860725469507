import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiDocker,
  SiGithub,
  SiPostgresql,
  SiPostman
} from "react-icons/si";

function Toolstack() {
  const tools = [
    { icon: SiPostgresql, name: "PostgreSQL", color: "#336791" },
    { icon: SiDocker, name: "Docker", color: "#2496ED" },
    { icon: SiGithub, name: "GitHub", color: "#ffffff" },
    { icon: SiPostman, name: "Postman", color: "#FF6C37" }
  ];

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {tools.map((tool, index) => (
        <Col xs={6} md={3} lg={2} className="tech-icons" key={index}>
          <div className="tech-icon-container">
            <tool.icon style={{ color: tool.color }} />
            <p className="tech-name">{tool.name}</p>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Toolstack;
