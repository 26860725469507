import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiGit,
  DiMongodb,
  DiNodejs,
  DiReact,
  DiRust
} from "react-icons/di";

function Techstack() {
  const techs = [
    { icon: DiNodejs, name: "Node.js", color: "#68a063" },
    { icon: DiReact, name: "React", color: "#61DAFB" },
    { icon: DiMongodb, name: "MongoDB", color: "#4DB33D" },
    { icon: DiGit, name: "Git", color: "#F1502F" },
    { icon: DiRust, name: "Rust", color: "#DEA584" }
  ];

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {techs.map((tech, index) => (
        <Col xs={6} md={3} lg={2} className="tech-icons" key={index}>
          <div className="tech-icon-container">
            <tech.icon style={{ color: tech.color }} />
            <p className="tech-name">{tech.name}</p>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;
