import React, { useEffect, useState } from "react";
import preloader from "../Assets/pre.svg";

const loadingPhrases = [
  "Brewing virtual coffee...",
  "Waking up lazy pixels...",
  "Feeding the server hamsters...",
  "Charging flux capacitor...",
  "Compiling brilliant ideas...",
  "Generating digital magic...",
  "Initializing awesome mode...",
  "Tweaking the algorithms...",
  "Polishing the pixels...",
  "Warming up the engines...",
  "Bending time and space...",
  "Loading quantum bits...",
  "Summoning digital powers...",
  "Calculating universe answer...",
  "Debugging rubber ducks...",
  "Assembling cool features...",
  "Reticulating splines...",
  "Generating random excuse...",
  "Converting caffeine to code...",
  "Dividing by zero...",
  "Spawning digital minions...",
  "Accelerating to warp speed...",
  "Downloading more RAM...",
  "Optimizing neural pathways...",
  "Searching for infinity...",
  "Untangling quantum knots...",
  "Charging creative batteries...",
  "Synthesizing digital dreams...",
  "Collecting magical bytes...",
  "Preparing mind-blowing stuff..."
];

function Pre(props) {
  const [fadeOut, setFadeOut] = useState(false);
  const [display, setDisplay] = useState(true);
  const [phrase, setPhrase] = useState("");

  useEffect(() => {
    // Seleccionar una frase aleatoria al montar el componente
    const randomPhrase = loadingPhrases[Math.floor(Math.random() * loadingPhrases.length)];
    setPhrase(randomPhrase);

    // Cambiar la frase cada 2 segundos
    const phraseInterval = setInterval(() => {
      const newPhrase = loadingPhrases[Math.floor(Math.random() * loadingPhrases.length)];
      setPhrase(newPhrase);
    }, 2000);

    return () => clearInterval(phraseInterval);
  }, []);

  useEffect(() => {
    if (!props.load) {
      setFadeOut(true);
      setTimeout(() => {
        setDisplay(false);
        document.body.style.overflow = 'visible';
      }, 400);
    } else {
      setFadeOut(false);
      setDisplay(true);
      document.body.style.overflow = 'hidden';
    }
  }, [props.load]);

  if (!display) return null;

  return (
    <div id="preloader" className={fadeOut ? 'fade-out' : ''}>
      <div className="loader-container">
        <img src={preloader} alt="Loading..." />
        <span className="loader-text">{phrase}</span>
      </div>
    </div>
  );
}

export default Pre;
