import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import Type from "./Type";

function Home() {
  const edad = new Date().getFullYear() - 1997;

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container>
          <Row className="home-content align-items-center justify-content-center">
            <Col md={8} className="home-header text-center">
              <h1 className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'm
                <strong className="main-name"> Agustin Demarco</strong>
              </h1>

              <div className="heading-description">
                <p>
                  {edad} years old full-stack developer with a passion for
                  back-end development. I create efficient solutions using
                  <span className="highlight"> Node.js</span> and modern frameworks.
                </p>
              </div>

              <div className="type-writer">
                <Type />
              </div>

              <div className="social-links">
                <a
                  href="https://github.com/agustindemarco"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                  aria-label="GitHub"
                >
                  <AiFillGithub />
                </a>
                <a
                  href="https://www.linkedin.com/in/agustin-demarco"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                  aria-label="LinkedIn"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
