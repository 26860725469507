import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import { Document as PDFDocument, pdfjs, Page as PDFPage } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import cvPDF from "../../Assets/cv.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  return (
    <Container fluid className="resume-section">
      <Container>
        <div className="resume-header-container">
          <h1 className="heading">
            My <span className="accent">Resume</span>
          </h1>
          <a
            href={cvPDF}
            target="_blank"
            rel="noreferrer"
            className="cv-download-btn"
            title="Download CV"
          >
            <FiDownload /> <span>CV</span>
          </a>
        </div>

        <Row className="resume-content">
          <Col className="pdf-container">
            <PDFDocument
              file={cvPDF}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-document"
            >
              <PDFPage
                pageNumber={pageNumber}
                className="pdf-page"
                scale={1.2}
              />
            </PDFDocument>

            {numPages > 1 && (
              <div className="page-controls">
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={() => changePage(-1)}
                  className="page-btn"
                >
                  ‹
                </button>
                <span className="page-info">
                  {pageNumber} / {numPages}
                </span>
                <button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={() => changePage(1)}
                  className="page-btn"
                >
                  ›
                </button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default ResumeNew;
